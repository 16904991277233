<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Imagens - {{ content.cont_titulo }}</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'contentphotos', params: {content_id: cont_id}}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <galeria-imagem-content v-on:atualiza="loadContentWithPhotos" :content="content"></galeria-imagem-content>

</span>
</template>

<script>
import GaleriaImagemContentComponent from "./partial/GaleriaImagemContentComponent";

export default {
    name: "AddImagemContentComponent",
    created() {
        this.loadContentWithPhotos()
    },
    props: {
        cont_id: {
            require: true
        }
    },
    data() {
        return {
            content: {},
            btnLabel: 'Salvar',
        }
    },
    methods: {
        loadContentWithPhotos() {
            this.$store.dispatch('loadContentWithPhotos', this.cont_id)
                .then(response => this.content = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        GaleriaImagemContent: GaleriaImagemContentComponent,
    }
};
</script>

<style scoped>

</style>
