<template>
<span>

    <form-imagem-content v-on:atualizar="atualizarContents" :content="content"></form-imagem-content>

    <v-container fluid>
        <v-layout row wrap>

            <v-flex xs6 sm6 md4 lg3 xl3 v-for="(imagem, index) in content.fotos" :key="index" pa-2>
                <v-card>
                    <v-img class="img-preview" :src="`${base_url}upload/content_photo/m/${imagem.cont_ft_foto}`">
                    </v-img>

                    <v-card-actions class="justify-center">
                        <v-btn icon>
                            <v-icon @click.prevent="destroyContentPhoto(imagem, index)">highlight_off</v-icon>
                        </v-btn>
                        <v-divider class="mx-2" inset vertical></v-divider>
                        <v-btn icon>
                            <v-icon @click.prevent="updateCapa(imagem)" :class="{ 'is-capa': imagem.cont_ft_capa }">star</v-icon>
                        </v-btn>

                    </v-card-actions>

                </v-card>

            </v-flex>

        </v-layout>
    </v-container>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import FormImagemContentComponent from "./FormImagemContentComponent";

export default {
    name: "GaleriaImagemContentComponent",
    props: {
        content: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    cont_id: '',
                    cont_nome: '',
                }
            },
        },
        require: true,
    },

    data() {
        return {
            contentPhoto: {
                require: false,
                type: Object | Array,
                default: () => {
                    return {
                        cont_ft_id: '',
                        content_id: '',
                        cont_ft_titulo: '',
                        cont_ft_legenda: '',
                        cont_ft_fotografo: '',
                        cont_ft_icone: '',
                        cont_ft_foto: '',
                        cont_ft_link: '',
                        cont_ft_codigo: '',
                        cont_ft_capa: '',
                        cont_ft_status: '',
                    }
                }
            },
            base_url: URL_BASE
        }
    },

    methods: {
        atualizarContents() {
            this.$emit('atualiza')
        },
        destroyContentPhoto(item, index) {
            if (item.cont_ft_capa) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Atenção!',
                    text: 'Esta foto é capa, escolha outra como capa antes de excluí-la',
                    showConfirmButton: true,
                    timer: 6000
                });
            } else {
                this.$swal({
                    title: 'Você tem certeza?',
                    text: "Você não poderá reverter isso",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#D32F2F',
                    cancelButtonColor: '#1976D2',
                    confirmButtonText: 'Sim, Exclua!'
                }).then((result) => {
                    if (result.value) {
                        this.$store.dispatch('destroyContentPhoto', item.cont_ft_id)
                            .then(response => {
                                if (response) {
                                    this.content.fotos.splice(index, 1);
                                } else {
                                    this.$swal({
                                        position: "center",
                                        icon: "error",
                                        title: 'Opssss!',
                                        text: 'Erro ao excluir dados',
                                        showConfirmButton: true,
                                        timer: 6000
                                    });
                                }
                            })
                            .catch(error => {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Algo errado aconteceu!',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            })
                    }
                })
            }

        },
        updateCapa(imagem) {
            this.$store.dispatch('updateContentPhotoCapa', {
                    cont_ft_id: imagem.cont_ft_id,
                    content_id: imagem.content_id,
                    cont_ft_capa: true
                })
                .then((response) => {
                    this.$emit('atualiza')
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar Capa',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formImagemContent: FormImagemContentComponent,
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
}

.is-capa {
    color: darkorange !important;
}
</style>
